<template>
  <form @submit.prevent="submit">
    <div class="field">
      <div class="control">
        <input
          class="input is-white is-uppercase"
          :class="{ 'is-danger': errors.has('vrm') }"
          type="text"
          v-validate="'required|alpha_num|min:2|max:7'"
          placeholder="VRM"
          v-model="search.vrm"
          name="vrm"
        />
      </div>
    </div>
    <div class="field has-addons">
      <div class="control is-expanded">
        <input
          class="input is-white"
          :class="{ 'is-danger': errors.has('mileage') }"
          type="text"
          placeholder="Mileage"
          name="mileage"
          v-validate="'integer|min:0|max:500000'"
          v-model="search.mileage"
          title="Max. 250,000 miles"
          v-tippy="{ placement: 'bottom' }"
        />
      </div>
      <div class="control" v-if="isInsurer">
        <span class="button is-soft" @click.prevent="toggleFilter">
          <span class="icon">
            <i class="fas fa-calendar-check"></i>
          </span>
        </span>
      </div>
    </div>
    <TableFilter
      @save="search.date = $event.date"
      @close="toggleFilter"
      :date-min="dateRange.min"
      :date-max="dateRange.max"
      v-if="filterIsActive"
    />
    <div class="field">
      <button
        type="submit"
        class="button is-fullwidth is-soft is-rounded"
        :class="{ 'is-loading': $wait.is('loading') }"
      >
        Search
      </button>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import * as companion from 'modules/companion/services'
import * as layout from 'modules/layout/services'
export default {
  name: 'CompanionVehicleSearch',
  components: {
    TableFilter: () =>
      import('core/modules/table-filter/components/TableFilter')
  },
  computed: {
    ...mapGetters({
      isInsurer: 'auth/isInsurer'
    }),
    dateRange: () => layout.dateRange(),
    vrm() {
      return this.search.vrm.toUpperCase()
    }
  },
  data: () => ({
    search: {
      vrm: '',
      mileage: '',
      date: null
    },
    filterIsActive: false
  }),
  methods: {
    toggleFilter() {
      this.filterIsActive = !this.filterIsActive
    },
    async submit() {
      const valid = await this.$validator.validate()
      if (valid) {
        this.$wait.start('loading')

        try {
          if (!this.search.mileage) {
            this.search.mileage = null
          }

          await companion.value(this.search)

          this.clear()
          this.$emit('success')
        } finally {
          this.$wait.end('loading')
        }
      }
    },
    clear() {
      this.search = {
        vrm: '',
        mileage: '',
        date: null
      }
    }
  }
}
</script>
